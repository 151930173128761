@import '../variables';

// sizes

.base {
  font-family: $cerebri;
}

.display {
  font-weight: $fw-1;
  line-height: 1.2;
  margin: 0;
  // margin-bottom: 5px;
  color: $primary-900;
  letter-spacing: -1px;

  @include for-size(phone-only) {
    font-weight: $fw-1;
    letter-spacing: -0.5px;
    font-size: 30px;
  }

  @include for-size(tablet-portrait-up) {
    font-weight: $fw-1;
    letter-spacing: -0.5px;
    font-size: 40px;
  }

  @include for-size(desktop-up) {
    font-size: 50px;
  }
}

.headline {
  font-weight: $fw-1;
  margin: 0;
  margin-bottom: 8px;
  color: $primary-900;
  letter-spacing: -0.5px;
  font-size: $size-9;
  line-height: $line-height-9;

  @include for-size(phone-only) {
    font-size: $size-7;
    line-height: $line-height-7;
  }
}

.subheadlineLarge {
  font-weight: $fw-1;
  margin: 0;
  margin-bottom: 8px;
  color: $primary-900;
  letter-spacing: -0.5px;
  font-size: $size-8;
  line-height: $line-height-8;

  @include for-size(phone-only) {
    font-size: $size-6;
    line-height: $line-height-6;
  }
}

.subheadline {
  font-weight: $fw-2;
  line-height: 1.5;
  margin: 0;
  // margin-bottom: 5px;
  letter-spacing: -0.3px;
  color: $blue-120;

  @include for-size(phone-only) {
    font-size: $size-5;
    line-height: $line-height-5;
  }

  // @include for-size(tablet-portrait-up) {
  //   font-size: 24px;
  // }

  // @include for-size(desktop-up) {
  //   font-size: $size-7;
  //   line-height: $line-height-7;
  // }

  &.huge {
    @include for-size(phone-only) {
      font-size: 28px;
      line-height: 1.4;
    }

    // @include for-size(tablet-portrait-up) {
    //   font-size: 32px;
    // }

    // @include for-size(desktop-up) {
    //   font-size: 36px;
    // }
  }
}

.intro {
  font-weight: $fw-2;
  line-height: 1.25;
  margin: 0;
  // margin-bottom: 5px;
  color: $blue-120;

  @include for-size(phone-only) {
    font-size: 18px;
  }

  @include for-size(tablet-portrait-up) {
    font-size: 20px;
  }

  @include for-size(desktop-up) {
    font-size: 24px;
  }
}

.headingTwo {
  font-weight: $fw-2;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 5px;
  letter-spacing: -0.3px;
  color: $blue-120;

  @include for-size(phone-only) {
    font-size: 16px;
  }

  @include for-size(tablet-portrait-up) {
    font-size: 18px;
  }

  @include for-size(desktop-up) {
    font-size: 22px;
  }
}

.sectionBody {
  font-size: $size-4;
  line-height: $line-height-4;
  color: $black;
  margin: 0;
  margin-bottom: 8px;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
  }
}

.blogHead {
  font-size: $size-3;
  line-height: $line-height-1;
  font-weight: $fw-3;
  color: $primary-400;
  margin-bottom: 8px;
}

.subhead {
  font-size: $size-5;
  line-height: $line-height-4;
  font-weight: $fw-1;
  color: $neutral-500;
  margin-bottom: 8px;

  @include for-size(phone-only) {
    font-size: $size-4;
    line-height: $line-height-3;
  }
}

.body {
  margin: 0;
  margin-bottom: 8px;
  color: $gray-80;
  font-size: $size-4;
  line-height: $line-height-4;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
  }
}

// variations
.white {
  color: $white;
}

.grey {
  color: $neutral-500;
}

.base {

  // for higher specificity
  &.white {
    color: $white;
  }
}

.paragraph {
  margin-bottom: 16px;
}

.center {
  text-align: center;
}

.black {
  color: $black;
}

// .whiteCenter {
//   color: $white;
//   text-align: center;
// }

// .darkblueCenter {
//   color: $blue-120;
//   text-align: center;
// }
.blue {
  color: $lapis-lazuli;
}

.blogBlue {
  color: $primary-400;
}

.darkblue {
  color: $blue-120;
}

.primary {
  font-size: 18px;
  line-height: 1.475;
}

.secondary {
  font-size: 14px;
  color: $gray-80;
  hyphens: manual;
  font-weight: 400;
  line-height: 1.475;
  margin-bottom: 0;
}

.h2 {
  margin-left: -1px;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-7;
    line-height: $line-height-7;
  }

  @include for-size(tablet-portrait-up) {
    font-size: $size-7;
    line-height: $line-height-7;
  }

  @include for-size(desktop-up) {
    font-size: $size-8;
    line-height: $line-height-8;
  }
}

.h3 {
  margin-left: -1px;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-5;
    line-height: $line-height-5;
  }

  @include for-size(tablet-portrait-up) {
    font-size: $size-6;
    line-height: $line-height-6;
  }

  @include for-size(desktop-up) {
    font-size: $size-6;
    line-height: $line-height-6;
  }
}

.h4 {
  margin-bottom: 16px;

  @include for-size(phone-only) {
    font-size: $size-4;
    line-height: $line-height-4;
  }

  @include for-size(tablet-portrait-up) {
    font-size: $size-5;
    line-height: $line-height-5;
  }

  @include for-size(desktop-up) {
    font-size: $size-5;
    line-height: $line-height-5;
  }
}