@import '@hiredigital/ui/variables';

.sectionContainer {
  background: $primary-900;
  overflow: hidden;
  user-select: none;

  &.removeBackground {
    background: transparent;
  }

  .section {
    position: relative;
    display: flex;
    padding: 50px 0;
    height: 50px;
    user-select: none;
    overflow: hidden;
    will-change: opacity;
    animation: fadein 3s;
  }
}

.superSection {
  position: relative;
  display: flex;
  padding: 20px 0;
  height: 50px;
  user-select: none;
  overflow: hidden;
  will-change: opacity;
  animation: fadein 3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.marquee {
  display: flex;

  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 40s linear infinite;
  .rightRow & {
    animation: marqueeRight 40s linear infinite;
  }

  // &:hover {
  //   animation-play-state: paused;
  // }
}

.imageContainer {
  height: 50px;
  width: 100px;
  padding-right: 40px;
  // &:last-of-type {
  //   padding-right: 0;
  // }
}

.image {
  height: 50px;
  width: 100px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@keyframes marqueeRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}
