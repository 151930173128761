@import '@hiredigital/ui/variables';

.ruler {
  border-bottom: 1px solid $gray-10;
}

.anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}

.addMargin {
  margin-top: 30px;
}

.section {
  padding: 80px 20px;
  box-sizing: content-box;
  margin: auto;
  max-width: $content-width;

  @include for-size(tablet-landscape-down) {
    padding: 70px 20px;
  }

  @include for-size(phone-only) {
    padding: 50px 20px;
  }
}

.medium {
  max-width: 800px;
}

.large {
  max-width: 1200px;
}

.innerContainerSection {
  padding: 50px 20px;

  // @include for-size(phone-only) {
  //   padding: 50px 10px;
  // }
}

.container {
  width: 100%;
  position: relative;
  &.splitContainer {
    display: flex;

    @include for-size(phone-only) {
      flex-direction: column;
    }
  }
}

.containerCircle {
  background-color: $blue-5;
  position: relative;
}

.containerLightblue {
  background-color: $blue-5;
}

.containerImageBlue {
  display: flex;
  margin: auto;

  // max-width:1200px;
  background-image: linear-gradient(
      to right,
      hsla(225, 58%, 56%, 1) 100%,
      hsla(225, 58%, 56%, 1) 100%
    ),
    linear-gradient(to left, hsla(225, 58%, 56%, 1) 100%, hsla(225, 58%, 56%, 1) 100%),
    // linear-gradient(to right, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 1) 120%),
    url('/img/triangles.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color, multiply;
}

.containerDarkblue {
  background: radial-gradient(circle at 100% 50%, $blue-120, $blue-100 50%);
  background-color: $blue-120;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.containerEnterpriseSplit {
  // background: $blue-5;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    background: $primary-dark;
    top: 50%;
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: -1;
  }

  .gridContainer {
    margin-bottom: 0;
  }
}

.containerProfessionalSplit {
  background: $blue-5;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    background: $white;
    top: 50%;
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: -1;
  }

  .gridContainer {
    margin-bottom: 0;
  }
}

.containerEnterpriseFull {
  background: $primary-900;
  position: relative;
  z-index: 1;

  .gridContainer {
    margin-bottom: 0;
  }
}

.containerPrimaryblue {
  background-image: url('/img/main.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $blue-100;
}

.containerLightblueGradient {
  background: linear-gradient(180deg, $blue-5 0%, hsla(210, 100%, 100%, 1) 100%);
}

.containerPlainWhite {
  background-color: $white;
}

.leadgen {
  padding: 50px 20px;
  z-index: $z-base;

  @include for-size(phone-only) {
    width: 100%;
  }
}

.image {
  max-width: 600px;
  max-height: 600px;
}

.split {
  padding: 120px 40px;
  flex-basis: 50%;
  margin: auto;
  box-sizing: border-box;

  @include for-size(phone-only) {
    padding: 40px 30px;
    margin: inherit;
    flex-basis: auto; // workaround for ie
  }

  &:first-of-type {
    .splitContent {
      margin-left: auto;
    }
  }

  &:last-of-type {
    .splitContent {
      margin-right: auto;
    }
  }
}

.splitContent {
  max-width: 560px;
}
